import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from '@mui/material';
import Footer from '../Footer/Footer';


const Revolutionarythought = () => {
    const [documents, setDocuments] = useState([
        {   id: 1, 
            date: '30th of January 2023',
             title: '‘The Revolutionary Thought’ Issue 1(I – 2023)', 
             link: 'https://archive.org/details/the-revolutionary-thought-issue-1-i-2023'
        },
        {   id: 2, 
            date: '30th of September 2023',
             title: 'The Revolutionary Thought Issue 2(IV – 2023)', 
             link: 'https://archive.org/details/the-revolutionary-thought-issue-2-ii-2023'
        },
        { id: 3, 
          date: '8th of August 2023', 
          title: 'The Revolutionary Thought Issue 3(III – 2023)',
           link: 'https://reader.crcircp.com/2023/08/08/the-revolutionary-thought-issue-3iii-2023/' 
        },
        { id: 4,
         date: '16th of May 2023',
          title: 'The Revolutionary Thought Issue 4(II - 2023)',
          link: 'https://archive.org/details/the-revolutionary-thought-issue-4-iv-2023'
         },
        { id: 5, 
        date: '24th of January 2024',
        title: '‘The Revolutionary Thought’ Issue 5(V – 2024)',
        link: 'https://archive.org/details/the-revolutionary-thought-issue-5-i-2024' 
    },
    { id: 6, 
        date: '7th of August 2024',
        title: '‘The Revolutionary Thought’ Issue 6(Vi – 2024)',
        link: 'https://archive.org/details/the-revolutionary-thought-issue-6-vi-2024' 
    },
    { id: 7, 
        date: '7th of August 2024',
        title: '‘The Revolutionary Thought’  Issue 7( VII - 2024 )',
        link: 'https://archive.org/details/the-revolutionary-thought-issue-7-vii-2024' 
    },

      { id: 8, 
        date: '7th of August 2024',
        title: 'The Revolutionary Thought Issue 8( VIII 2024 )',
        link: 'https://archive.org/details/the-revolutionary-thought-issue-8-viii-2024/mode/2up' 
    },
    { id: 9, 
        date: '15th of Jan 2025',
        title: 'The Revolutionary Thought Issue 9( IX 2025 )',
        link: 'https://archive.org/details/the-revolutionary-thought-issue-9-ix-2025' 
    },




    ]);
    const [filter, setFilter] = useState('');

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredDocuments = documents.filter((doc) =>
        doc.title.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <div style={{ textAlign: 'center' }}>

             {/* Logo and text */}
            <div className="d-flex align-items-center" style={{ maxWidth: '100%', margin: '0 auto', marginTop: '10px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)' }}>
                <a href="/" style={{ textDecoration: 'none' }}>
                    <div className="d-flex justify-content-start bordered-div" style={{ maxWidth: '10%', margin: '10px 20px 10px 10px', borderRadius: '70px', borderColor: 'white' }}>
                        <img className="img-fluid" src="img/logo.png" alt="Central Revolutionary Committee" />
                    </div>
                </a>
                <div style={{ borderLeft: '2px solid red', height: '50px', margin: '0 90px', padding: '0 20px', marginLeft: '-70%' }}>
                    <p style={{ fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)' }}>
                    'The Revolutionary Thought'
                    </p>
                </div>
            </div>
            <div style={{ marginBottom: '20px',marginTop:'50px' }}>
            <h2>The Revolutionary Thought</h2>
            <TextField
                label="Filter by Title"
                variant="outlined"
                value={filter}
                onChange={handleFilterChange}
                style={{ marginBottom: '10px' ,width: '50%',marginTop:'10px' }}
            />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow style={{ fontWeight: 'bold',fontFamily:'Jost'}}>
                                <TableCell align="left" style={{ fontWeight: 'bold', color: 'black' }}>Publication Date</TableCell>
                                <TableCell align="left" style={{ fontWeight: 'bold', color: 'black' }}>Title</TableCell>
                                <TableCell align="left" style={{ fontWeight: 'bold', color: 'black' }}>View</TableCell>
                            </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredDocuments.map((document) => (
                            <TableRow key={document.id}>
                                <TableCell>{document.date}</TableCell>
                                <TableCell>{document.title}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" href={document.link} target="_blank">
                                       Read
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <Footer></Footer> */}
        </div>
    );
};

export default Revolutionarythought;
